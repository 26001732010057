<template>
  <div class="card full-page">
    <div class="card-header">
      <b-alert v-if="!canCreate" show variant="primary">{{
        $t('blocked_feat_activities')
      }}</b-alert>
      <h3 class="my-2">
        {{ $t('title_activities_' + params.type_id) }}
      </h3>
      <div class="d-flex w-100">
        <div v-for="(filter, i) in filters" :key="i" class="mr-1">
          <button
            class="btn filter-button"
            :class="{ active: filter.active }"
            @click="filterData(filter.filterName)"
          >
            {{ $t(filter.title) }}
          </button>
        </div>
        <div class="ml-auto">
          <b-form-input
            id="search"
            v-model="params.search"
            :placeholder="$t('Search')"
            debounce="2000"
          />
        </div>
      </div>
    </div>
    <div class="card-body inner-scroll">
      <div class="row pt-1">
        <ActivityCard
          v-for="activity in items"
          :key="activity.id"
          :activity="activity"
          :restrictOperations="!canCreate"
        />
        <div v-if="!items.length && requestDone" class="d-flex m-auto flex-column">
          <NotFound />
        </div>
        <div id="scroll-div" style="min-height: 2px; width: 100%" class="" />
      </div>
    </div>
    <div class="card-footer footer-shadow">
      
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BAlert } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import ActivityCard from '@/components/ActivityCard'
import NotFound from '@/components/NotFound'
import userCan from '@/libs/user-can'
import ActivityService from '@/services/ActivityService'
import STATUS from '@/services/shared/constants/status'

export default {
  name: 'Activities',
  components: {
    NotFound,
    BButton,
    BFormInput,
    BAlert,
    ActivityCard,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      ActivityService: new ActivityService(),
      items: [],
      last_page: 10,
      observer: null,
      loadAndRedir: false,
      requestDone: false,
      params: {
        search: undefined,
        type_id: this.$route.fullPath.split('/').pop(),
        page: 0,
        per_page: 6,
        status: STATUS.published,
      },
      show: false,
      filters: [
        {
          title: 'Active',
          filterName: STATUS.published,
          active: 1,
        },
        {
          title: 'Draft',
          filterName: STATUS.draft,
          active: 0,
        },
      ],
      canCreateLegacyActivities: true,
    }
  },
  computed: {
    canCreate() {
      return false
      // return userCan(this.$store.state.auth.user.plan, 'activities_create')
    },
  },
  watch: {
    $route() {
      this.params.page = 1
      this.params.last_page = 10
      this.items = []
      this.params.type_id = this.$route.fullPath.split('/').pop()
      this.getData()
    },
    'params.search': function (newVal, oldVal) {
      if (newVal.length === 0) this.params.search = undefined
      this.filterData(STATUS.published)
    },
  },
  mounted() {
    this.observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting === true) {
          if (this.last_page !== this.params.page) {
            this.params.page++
            this.getData()
          }
        }
      },
      { threshold: [0] }
    )
    this.observer.observe(document.querySelector('#scroll-div'))

    this.$posthog.onFeatureFlags(() => {
      if (
        this.$store.state.auth.user.has_saas_access &&
        !this.$posthog.isFeatureEnabled('allow-legacy-activities')
      ) {
        this.canCreateLegacyActivities = false
      }
    })
  },
  methods: {
    getData() {
      this.requestDone = false
      this.ActivityService.index(this.params).then(res => {
        this.last_page = res.last_page
        const orderFunc = function (a, b) {
          if (a.updated_at < b.updated_at) {
            return 1
          }
          if (a.updated_at > b.updated_at) {
            return -1
          }
          return 0
        }
        let ordered = res.data.sort(orderFunc)
        this.items = this.items.concat(ordered)
        this.requestDone = true
        if (res.data.length == 0 && !this.loadAndRedir) {
          this.loadAndRedir = true
          this.filterData(STATUS.draft)
        }
      })
    },
    filterData(value) {
      if (this.requestDone) {
        this.items = []
        this.params.page = 1
        this.last_page = 1
        this.filters.forEach(filter => {
          if (filter.filterName === value) {
            filter.active = 1
            this.params.status = filter.filterName
          } else {
            filter.active = 0
          }
        })
        this.getData()
      }
    },
  },
}
</script>

<style scoped>
::v-deep .alert {
  width: 100%;
  margin: 0 !important;
}

button.filter-button {
  background-color: #f8f8f8;
  color: #0e1a1a;
}

button.active {
  background-color: #f2e178;
  color: #ffffff;
}
</style>
